import * as types from '../actionTypes/TemplatesActionTypes';

/** Initial State of notification templates */
const templatesInitialState = {
  templatesData: [],
  totalRecords: 0,
  isTemplatesLoading: false,
  updatedTemplates: {},
  cognitoTokens: {
    refreshToken: '',
    idToken: '', 
    accessToken: '',
    tokenType: '',
  },
};

/** Configuration Reducer */
const templatesReducer = (state = templatesInitialState, action) => {
  switch (action.type) {
    case types.FETCH_NOTIFICATION_TEMPLATES_REQUEST:
      return {
        ...state,
        isTemplatesLoading: true,
      };
    case types.FETCH_NOTIFICATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        templatesData: action.data.data.data,
        totalRecords: action.data.data.totalRecords,
        isTemplatesLoading: false,
      };
    case types.FETCH_NOTIFICATION_TEMPLATES_FAILURE:
      return {
        ...state,
        isTemplatesLoading: false,
      };
      case types.FETCH_TEMPLATES_BY_NAME_SUCCESS:
        return {
          ...state,
          isTemplatesLoading: false,
          updatedTemplates: action.data.data && action.data.data 
        };
      case types.SET_UPDATED_TEMPLATES_DATA:
        const data = action.data;
        return {
          ...state,
          updatedTemplates: {...state.updatedTemplates, ...data} 
        };
      case types.RESET_UPDATED_TEMPLATES_DATA:
        return {
          ...state,
          updatedTemplates: templatesInitialState.updatedTemplates
        };
      case types.SET_COGNITO_TOKENS:
        return {
          ...state,
          cognitoTokens: action.data
        };
    default:
      return state;
  }
};

export { templatesInitialState, templatesReducer };