import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import * as Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginBottom: '5px',
    backgroundColor: '#003057 !important',
    height: '64px',
    color: 'white !important',
  },
  titleWrapper: {

    flexGrow: 1,
    position: 'relative',
    top: '3px',
    borderLeft: '1px solid #fff',
    fontWeight: 600,
    fontFamily: 'sans-serif !important',
    paddingLeft: '10px',
    paddingBottom: '.5rem',
    marginLeft: '17px',
    height: '35px',
    minWidth: '150px',


    [`@media only screen and (min-width: 1563px)`]: {
      top: '0px',
    },
  },
  title: {
    color: '#FFFFFF',
    textDecoration: 'none',
    position: 'relative',
    fontWeight: 600,
    fontFamily: 'sans-serif !important',
    paddingLeft: '5px',
    top: '-1px',
    fontSize: '1.3rem',

    [`@media only screen and (min-width: 1563px)`]: {
      top: '3px',
      fontSize: '1.7rem',
    },
  },

  logo:{
      position: 'relative',
      top: '5px',
      paddingRight: '.5rem',
  },
  toolbar:{
    minHeight: '64px',
  }
}));

export const Header = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${classes.globalHeader}` }>
      <AppBar position="fixed" className={classes.appBar} >
        <Toolbar className={classes.toolbar}>
          <Link href="/">
            <Logo.SpectrumReachLogoAlt className={classes.logo} width="125" height="85" v1="1200" v2="250" />
          </Link>
          <Typography variant="h5" className={classes.titleWrapper}>
            <NavLink exact className={classes.title} to="/">
              Notification Service
            </NavLink>
          </Typography>        
        </Toolbar>
      </AppBar>
    </div>
  );
};