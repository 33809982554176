import axios from 'axios';

export const apiCaller = ({ method, headers, endpointURL, data }) => {
  if (!endpointURL || !method) return false;

  const config = {
    method,
    url: endpointURL,
    data,
    headers: headers,
    withCredentials: true,
  };

  return axios.request(config);
};