/** Notification template Api End points */
export const notificationTemplateApiEndPoints = {
  fetchNotificationTemplates: `${process.env.REACT_APP_API_URL}Notification/Fetch`,
  deleteNotificationTemplate: `${process.env.REACT_APP_API_URL}Notification/Delete`,
  getTemplateByName: `${process.env.REACT_APP_API_URL}Notification/ByName`,
  createTemplate: `${process.env.REACT_APP_API_URL}Notification/Create`, 
  updateTemplate: `${process.env.REACT_APP_API_URL}Notification/Update`,
};

export const cognitoApiEndPoints = {
  uiURL: `${process.env.REACT_APP_UI_URL}`,
  loginURL: `${process.env.REACT_APP_COGNITO_URL}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LOGIN_CALL_BACK_URL}`,
  tokenURL: `${process.env.REACT_APP_COGNITO_URL}/oauth2/token`,
};