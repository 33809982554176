// A custom theme for this app
const theme = {
  "typography": { "fontFamily": `"Reach-Text-Regular", "Helvetica", "Arial", sans-serif` },
  "palette": { "common": { "black": "#000", "white": "#fff" }, 
  "background": { "paper": "#fff", "default": "#fafafa" }, 
  "primary": { "light": "rgba(223, 223, 223, 1)", 
  "main": "rgba(0, 48, 88, 1)", "dark": "rgba(0, 48, 88, 1)", "contrastText": "#fff" }, 
  "secondary": { "light": "rgba(0, 153, 216, 1)", 
  "main": "rgba(0, 98, 155, 1)", "dark": "rgba(0, 98, 155, 1)", "contrastText": "#fff" , "medBlue": "#00629b", "lightBlue": "#0099d8"}, 
  "error": { "light": "#e57373", "main": "#f44336", "dark": "#d32f2f", "contrastText": "#fff" }, 
  "text": { "primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.54)", 
  "disabled": "rgba(0, 0, 0, 0.38)", 
  "hint": "rgba(0, 0, 0, 0.38)" } 
},
}

export default theme;