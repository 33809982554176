/* eslint-disable linebreak-style */
import { constants as tokenConstants, getAuthItem } from './AuthUtils';

export function UseAuthorization(
  auth = tokenConstants.ACCESS_TOKEN,
  config = null,
) {
  let authToken;
  if (auth !== undefined) {
    authToken = getAuthItem(tokenConstants.ACCESS_TOKEN) || '';
  } else if (auth === tokenConstants.REFRESH_TOKEN) {
    authToken = getAuthItem(tokenConstants.REFRESH_TOKEN) || '';
  }
  if (authToken) {
    config = { headers: { Authorization: `Bearer ${authToken}` } } // eslint-disable-line no-param-reassign
  }
  return {
    headers: !authToken ? {} : { Authorization: `Bearer ${authToken}` },
  };
}
