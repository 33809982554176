import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import {Header} from './Header';
import Toasts from '../components/Toasts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
   toolbar:{
    minHeight: '94px',
  }
}));

/** Application Layout Component */
const Layout = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        <Toolbar className={classes.toolbar} />
        <Toasts />
        {children}
      </main>
    </div>
  );
};

export default Layout;
