import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";

export default function Select(props) {

  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    defaultValue,
    disabled = false,
    disableNone = false,
    customRoot,
    customFormControl,
  } = props;

  const SelectCustom = withStyles(() => (
      {
        root:customRoot
      }
      ))(MuiSelect);

  const FormControlCustom = withStyles(() => (
      {
        root: customFormControl
      }
    ))(FormControl);

  return (
    <FormControlCustom
      variant="outlined"
      error={!!error}
    >
      <InputLabel>{label}</InputLabel>
      <SelectCustom
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        <MenuItem disabled={disableNone} value="">
          None
        </MenuItem>
        {options &&
          options.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.title || item.description}
            </MenuItem>
          ))}
      </SelectCustom>
      {error && <FormHelperText>{error}</FormHelperText>}
</FormControlCustom>
  );
}
