import React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actionCreators/common/ToastsActionCreators';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(9),
  },
}));

/** Toast Messages Component */
const Toasts = (props) => {
  const { toasts } = props;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.showToast({
      ...toasts,
      isOpen: false,
    });
  };

  return toasts.isOpen && (
    <Snackbar
      className={classes.root}
      open={toasts.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert severity={toasts.type} onClose={handleClose}>
        {toasts.message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({
  toasts:
      state.toastsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (data) => {
    Actions.showToastMessage(dispatch, data);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
