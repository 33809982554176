import moment from 'moment';
import { DefaultTokenExpiryTime } from '../constants/CommonConstants';

export const constants = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  EXPIRY_TIME: 'expiryDateTime',
};

export const getAuthItem = (key) => window.localStorage.getItem(key);
export const setAuthItem = (key, value) => window.localStorage.setItem(key, value);
export const deleteAuthItem = (key) => window.localStorage.removeItem(key);

/** Save Access and Refresh Token */
export const saveToken = (data) => {
  setAuthItem(constants.ACCESS_TOKEN, data.accessToken ?? data.access_token);
  setAuthItem(constants.REFRESH_TOKEN, data.refresh_token);
  const expiryTime = moment().add(data.expires_in, 'seconds').format();
  setAuthItem(constants.EXPIRY_TIME, expiryTime);
};

/** Remove Tokens */
export const removeTokens = () => {
  deleteAuthItem(constants.ACCESS_TOKEN);
  deleteAuthItem(constants.REFRESH_TOKEN);
  deleteAuthItem(constants.EXPIRY_TIME);
};

/** check token expired or not * */
export const isTokenExpired = () => {
  const endDate = moment(
    getAuthItem(constants.EXPIRY_TIME)
    || moment().add(Number(DefaultTokenExpiryTime), 'seconds').format(),
  );
  const currentDate = moment();
  return moment.duration(endDate.diff(currentDate)).asMilliseconds() < 0;
};

/** Is user authenticated */
export const isUserAuthenticated = () =>
  getAuthItem(constants.ACCESS_TOKEN) && !isTokenExpired();
