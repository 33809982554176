import '../Fonts/import-fonts.css';
import { createMuiTheme } from '@material-ui/core/styles';

const Theme = {
  typography: {
    fontFamily: '"Reach-Text-Regular", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: 'rgba(223, 223, 223, 1)',
      main: 'rgba(0, 48, 88, 1)',
      dark: 'rgba(0, 48, 88, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(0, 153, 216, 1)',
      main: 'rgba(0, 98, 155, 1)',
      dark: 'rgba(0, 98, 155, 1)',
      contrastText: '#fff',
    },
    error: {
      lighter: '#fa7474fa',
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    grey: {
      light: '#cccccc',
      main: '#d8d8d8',
      dark: '#757575',
      slate: '#63738A',
      ash: '#f8f8f8',
    },
    blue: {
      main: '#003057',
      medium: '#00629b',
      light: '#0099d8',
      lighter: '#34c0e8',
      dark: '#001b33',
      // darkest: '#001b33e6',
      // For IE Browser changed to
      darkest: 'rgba(0,27,51,0.9019607843137255)',
    },
    green: {
      light: '#77cb50',
    },
    white: {
      alto: '#cecece',
      smoke: '#f5f5f5',
    },
  },
  overrides: {
    MuiTableHead: {
      root: {
        background: '#00629b',
      },
    },
    MuiTableCell: {
      head: {
        color: 'white !important',
        lineHeight: '2.5rem',
      },
      root: {
        padding: '0.7rem',
        fontSize: '1rem',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: 'white !important',
      },
      icon: {
        color: 'white !important',
      },
      root: {
        color: 'white !important',
      },
    },
    MuiFormControl: {
      root: {
        margin: '0rem .5rem .75rem 0rem',
      },
    },
    MuiTabs: {
      root: {
        minHeight: '40px',
      },
    },
    MuiTab: {
      root: {
        minHeight: '40px',
        background: '#d8d8d8',
        borderRadius: '4px 4px 0 0',
        '&$selected': {
          background: '#0099d8',
          color: 'white',
        },
        textTransform: 'capitalize',
      },
    },
  },
};

const CustomTheme = createMuiTheme(Theme);

export default CustomTheme;
