import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotificationTemplates from './modules/NotificationTemplates/NotificationTemplates'
import EditTemplate from './modules/NotificationTemplates/EditTemplate'
import LoginCallbackPage from './auth/Login/LoginCallback';
import Login from './auth/Login/Login';

/** Defining routing paths here */
export const getPath = {
  HOME_PATH: '/',
  EDIT_TEMPLATE: '/templates/update',
  LOGIN: '/login',
  LOGIN_CALLBACK: '/authentication/login-callback',
  LOGOUT: '/logout',
};

const Routes = () => (
    <Switch>
      <Route path={getPath.LOGIN_CALLBACK} component={LoginCallbackPage} />
      <Route exact path={getPath.LOGIN} component={Login} />
      <Route exact path={getPath.HOME_PATH} component={NotificationTemplates} />
      <Route exact path={getPath.EDIT_TEMPLATE} component={EditTemplate} />
    </Switch>
);

export default Routes;
