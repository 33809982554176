import React from "react";
import { Paper } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import TemplatesTable from "./TemplatesTable";
import SearchInput from "../../shared/Controls/SearchInput";
import PageHeader from "../../shared/components/PageHeader";
import { getNotificationTemplates, resetUpdatedtemplatesDataAction } from '../../redux/actionCreators/TemplatesActionCreators';
import { createUseStyles } from "react-jss";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import Controls from "../../shared/Controls/Controls";
import { push } from 'connected-react-router';
import { makeOrGetTableFiltersByKey } from "../../redux/selectors/common/UseTableSelectors";
import { isUserAuthenticated } from '../../utils/AuthUtils';

const isAuthenticated = isUserAuthenticated();

const useClasses = createUseStyles({
  page: {
    padding: "2rem",
    paddingBottom: "1rem",
  },
});

const StyledButton = styled.div`
  padding-top: 6px;
  padding-right: 40px;
  display: inline-flex;
`;

const NotificationTemplates = (props) => {
  const classes = useClasses();
  const handleAdd = async () => {
    props.resetUpdatedtemplatesData();
    window.location.href = '/templates/update';
  }

  return (
    !isAuthenticated
      ? window.location.href = './login'
      :
      <Paper className={classes.page}>
        <PageHeader
          title="Notification Templates"
          pageHeaderOptions={
            <>
              <StyledButton>
                <Controls.Button
                  text="Add New"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                  customButtonType="buttonGroupSingle"
                />
              </StyledButton>
              <SearchInput pageKey={"templates"}
                onSearchAction={() =>
                  props.dispatch(getNotificationTemplates())
                } />
            </>
          }
        />
        <TemplatesTable />
      </Paper>
  );
};

const makeOrGetMapStateToProps = () => {
  const getTemplatesFiltersByPageKey = makeOrGetTableFiltersByKey();
  const mapStateToProps = (state) => ({
    tableFilters: getTemplatesFiltersByPageKey(state, "templates"),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  resetUpdatedtemplatesData: () => {
    resetUpdatedtemplatesDataAction(dispatch);
  },
  dispatch,
});

export default connect(makeOrGetMapStateToProps, mapDispatchToProps)(NotificationTemplates);
