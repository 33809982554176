import { withRouter } from 'react-router-dom';
import { apiCaller } from '../../services/api';
import qs from "qs";
import { cognitoApiEndPoints } from '../../constants/apiConstants';
import { saveToken } from '../../utils/AuthUtils';

const apiUrl = cognitoApiEndPoints.tokenURL;

export const LoginCallbackPage = () => {
  let code = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const data = {
    grant_type: "authorization_code",
    code: code.code,
    redirect_uri: `${process.env.REACT_APP_LOGIN_CALL_BACK_URL}`,
    client_id: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
  };

  apiCaller({
    method: 'post',
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    endpointURL: apiUrl,
    data: qs.stringify(data)
  })
    .then((response) => {
      localStorage.setItem("access_token", response?.data?.access_token);
      localStorage.setItem("accessToken", response?.data?.access_token);
      localStorage.setItem("id_token", response?.data?.id_token);
      localStorage.setItem("refresh_token", response?.data?.refresh_token);
      localStorage.setItem("expires_in", response?.data?.expires_in);

      saveToken(localStorage);

      return window.location.href = '/';
    })
    .catch((error) => {
      throw error;

    });
};

export default withRouter(LoginCallbackPage);