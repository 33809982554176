/** notification-template endpoint success  */
export const FETCH_NOTIFICATION_TEMPLATES_SUCCESS = 'FETCH_NOTIFICATION_TEMPLATES_SUCCESS';

/** notification-template endpoint request  */
export const FETCH_NOTIFICATION_TEMPLATES_REQUEST = 'FETCH_NOTIFICATION_TEMPLATES_REQUEST';

/** notification-template endpoint failure  */
export const FETCH_NOTIFICATION_TEMPLATES_FAILURE = 'FETCH_NOTIFICATION_TEMPLATES_FAILURE';

export const DELETE_TEMPLATES_SUCCESS = 'DELETE_TEMPLATES_SUCCESS';
export const DELETE_TEMPLATES_REQUEST = 'DELETE_TEMPLATES_REQUEST';
export const DELETE_TEMPLATES_FAILURE = 'DELETE_TEMPLATES_FAILURE';

export const FETCH_TEMPLATES_BY_NAME_SUCCESS = 'FETCH_TEMPLATES_BY_NAME_SUCCESS';

export const GET_NOTIFICATION_TYPES_REQUEST = 'GET_NOTIFICATION_TYPES_REQUEST';
export const GET_NOTIFICATION_TYPES_SUCCESS = 'GET_NOTIFICATION_TYPES_SUCCESS';
export const GET_NOTIFICATION_TYPES_FAILURE = 'GET_NOTIFICATION_TYPES_FAILURE';

export const SET_UPDATED_TEMPLATES_DATA = 'SET_UPDATED_TEMPLATES_DATA';

export const UPDATE_TEMPLATES_REQUEST = 'UPDATE_TEMPLATES_REQUEST';
export const UPDATE_TEMPLATES_FAILURE = 'UPDATE_TEMPLATES_FAILURE';

export const RESET_UPDATED_TEMPLATES_DATA = 'RESET_UPDATED_TEMPLATES_DATA';

export const GET_FORMATTING_TYPES_REQUEST = 'GET_FORMATTING_TYPES_REQUEST';
export const GET_FORMATTING_TYPES_SUCCESS = 'GET_FORMATTING_TYPES_SUCCESS';
export const GET_FORMATTING_TYPES_FAILURE = 'GET_FORMATTING_TYPES_FAILURE';

/** Action Type to fetch login data */
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';

/** Action Type to fetch login data failure */
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const SET_COGNITO_TOKENS = 'SET_COGNITO_TOKENS';