import React, { useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid, Divider, Box } from "@material-ui/core";
import { Controlled as CodeMirror } from "react-codemirror2";
import Button from "@material-ui/core/Button";
import ReactHtmlParser from "react-html-parser";
import Controls from "../../shared/Controls/Controls";
import styled from "styled-components";
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../shared/components/Loader';
import * as Actions from '../../redux/actionCreators/TemplatesActionCreators';
import { isUserAuthenticated } from '../../utils/AuthUtils';

const useClasses = makeStyles((theme) => ({
  page: {
    padding: "2rem",
    paddingBottom: "1rem",
  },
  textFieldStyles: {
    width: '510px',
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),
  },
  button: {
    width: '200px',
    marginTop: '40px'
  },
}));

const StyledGrid = styled.div`
  max-width: 600px;
`;
const StyledButtonWrappper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 16px;
`;

export const EditTemplate = (props) => {
  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const templateName = parsed.name;
  const data = props.updatedTemplates;
  const codemirrorRef = useRef();
  const isAuthenticated = isUserAuthenticated();

  useEffect(() => {
    codemirrorRef.current.editor.display.wrapper.style.height = "500px";
    if (templateName) {
      props.loadTemplateByName();
    }
  }, []);

  const classes = useClasses();

  const handleChangeField = (event) => {
    const { value, name } = event && event.target;
    props.setUpdatedtemplatesData({ [name]: value });
  };

  var options = {
    lineNumbers: true,
    autoFocus: true,
    mode: "xml",
    theme: "xq-light",
  };

  const handleSave = () => {
    const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const templateName = parsed.name;
    templateName ? props.dispatch(Actions.updateTemplates()) : props.dispatch(Actions.createTemplates());
  }

  const isSaveDisabled = !data.htmlPart || !data.name || !data.subjectPart || !data.textPart;

  return props.isTemplatesLoading ? (
    <Loader isLoading={props.isTemplatesLoading} />
  ) : data ? (
    !isAuthenticated
    ? window.location.href = '/login'
    :
    <Paper className={classes.page}>
      <StyledTypography variant="h4" component="div">
        {templateName ? "Edit Notification" : "Add Notification"}
      </StyledTypography>
      <Divider />
      <Box mt={4} mb={4}>
        <Grid container>
          <Grid item>
            <form noValidate autoComplete="off" className={classes.root}>
              <Controls.Input
                name="name"
                label="Name"
                onChange={handleChangeField}
                value={data?.name}
                disabled={!!templateName}
                className={classes.textFieldStyles}
                title="Template name cannot be edited!"
              />
              <Controls.Input
                name="subjectPart"
                label="Subject Part"
                onChange={handleChangeField}
                value={data?.subjectPart}
                className={classes.textFieldStyles}
              />
              <Controls.Input
                name="textPart"
                label="Text Part"
                onChange={handleChangeField}
                value={data?.textPart}
                className={classes.textFieldStyles}
              />
            </form>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box mt={4} mb={4}>
            <StyledTypography variant="h5" component="div">
              Editor
            </StyledTypography>
          </Box>
          <CodeMirror
            value={data.htmlPart}
            options={options}
            ref={codemirrorRef}
            onBeforeChange={(editor, data, value) => {
              props.setUpdatedtemplatesData({ "htmlPart": value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledGrid>
            <Box mt={4} mb={4}>
              <StyledTypography variant="h5" component="div">
                Preview
              </StyledTypography>
            </Box>
            {ReactHtmlParser(data.htmlPart)}
          </StyledGrid>
        </Grid>
      </Grid>
      <StyledButtonWrappper>
        <Button
          disabled={isSaveDisabled}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </StyledButtonWrappper>
    </Paper>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    updatedTemplates: state.templatesReducer.updatedTemplates,
    isTemplatesLoading: state.templatesReducer.isTemplatesLoading,
  }
};

const mapDispatchToProps = (dispatch) => ({
  loadTemplateByName: () => {
    Actions.getTemplateByNameAction(dispatch);
  },
  setUpdatedtemplatesData: (data) => {
    Actions.setUpdatedtemplatesDataAction(dispatch, data);
  },
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);