import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import {
  useTableInitialState,
  useTableReducer,
} from './redux/reducers/common/UseTableReducer';
import {
  toastsInitialState,
  toastsReducer,
} from './redux/reducers/common/ToastsReducer';
import {
  templatesReducer,
  templatesInitialState,
} from './redux/reducers/TemplatesReducer';

const initialState = {
  useTableReducer: useTableInitialState,
  templatesReducer: templatesInitialState,
  toastsReducer: toastsInitialState
};

export const rootReducer = (history) =>
  combineReducers({
    useTableReducer,
    templatesReducer,
    toastsReducer,
    router: connectRouter(history),
  });

export { initialState };
