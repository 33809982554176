import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Store from './Store';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import theme from './assets/Themes/customTheme';
import { CssBaseline } from '@material-ui/core';

const store = Store();
ReactDOM.render(
  <ThemeProvider theme={createMuiTheme(theme)}>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <CssBaseline />
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
