import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { TableBody, TableCell, TableRow, } from '@material-ui/core';
import qs from 'qs';
import { push } from 'connected-react-router';
import UseTable from '../../shared/Controls/UseTable';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';
import * as Actions from '../../redux/actionCreators/TemplatesActionCreators';
import * as UseTableActions from '../../redux/actionCreators/common/UseTableActionCreators';
import { getTemplatesState } from '../../redux/selectors/TemplatesSelectors';
import Loader from '../../shared/components/Loader';
import ActionButton from '../../shared/Controls/ActionButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialog from '../../shared/Controls/ConfirmDialog';
import moment from 'moment';

export const templatesTableHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'createdTimestamp', label: 'Created Timestamp' },
    { id: 'subjectPart', label: 'Template\'s Subject' },
    { id: 'textPart', label: 'Template\'s Text' },
    { id: 'actions', label: 'Actions', disableSorting: true },
];

const TemplatesTable = (props) => {
    const isIntialMount = useRef(true);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: '',
    });
    useEffect(() => {
        let orderBy = 'name';
        let order = 'desc';
        let page = 0;
        let searchInput = '';

        if (isIntialMount.current) {
            isIntialMount.current = false;
            const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            const initialFilterValues = {
                pageKey: 'templates',
                newFilters: {
                    orderBy: 'name',
                    order: 'desc',
                    page: 0,
                    search: ''
                }
            };
            props.setTableFiltersAction(initialFilterValues);
            if (Object.keys(parsed).length !== 0) {
                order = parsed.order;
                orderBy = parsed.orderBy;
                page = parsed.page;
                searchInput = parsed.searchInput;
            }
        } else {
            order = props.tableFilters.order;
            orderBy = props.tableFilters.orderBy;
            page = props.tableFilters.page;
            searchInput = props.tableFilters.search;
        }
        props.dispatch(push({
            search: `?${new URLSearchParams({
                order,
                orderBy,
                page,
                searchInput,
            }).toString()}`,
        }));

        props.dispatch(Actions.getNotificationTemplates());
    }, [props.tableFilters.order, props.tableFilters.orderBy, props.tableFilters.page]);

    /** Reset Page Filters When page Unmounts */
    useEffect(() => () => {
        props.resetFiltersAction();
    }, []);

    const handleEdit = async (name) => {
        window.location.href = `/templates/update?name=${name}`;
    }

    const getTruncatedCell = (value) => {
        return value ? value.substring(0, 30) : value
    }

    const onDelete = (name) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        props.dispatch(Actions.deleteTemplate(name));
    };

    const renderTemplates = () => {
        return (
            <>
                <UseTable
                    headCells={templatesTableHeadCells}
                    totalRecords={props.totalRecords}
                    pageKey={'templates'}
                >
                    <TableBody>
                        {props.templatesData.map((item) => (
                            <TableRow key={item.name}>
                                <TableCell>
                                    <Tooltip title={item.name ? item.name : ''} arrow>
                                        <div> {getTruncatedCell(item.name)}</div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={item.createdTimestamp ? moment(item.createdTimestamp).format("MMMM Do YYYY, h:mm a") : ''} arrow>
                                        <div> {getTruncatedCell(moment(item.createdTimestamp).format("MMMM Do YYYY, h:mm a"))}</div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={item.subjectPart ? item.subjectPart : ''} arrow>
                                        <div> {getTruncatedCell(item.subjectPart)}</div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={item.textPart ? item.textPart : ''} arrow>
                                        <div> {getTruncatedCell(item.textPart)}</div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <ActionButton
                                        color="primary"
                                        onClick={() => handleEdit(item.name)}
                                    >
                                        <EditOutlinedIcon fontSize="small" />
                                    </ActionButton>
                                    <ActionButton
                                        color="secondary"
                                        onClick={() => {
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: `Are you sure you want to delete template ${item.name}?`,
                                                onConfirm: () => {
                                                    onDelete(item.name);
                                                },
                                            });
                                        }}
                                    >
                                        <DeleteForeverIcon fontSize="small" />
                                    </ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </UseTable>
            </>
        );
    }
    return <>
        <Loader isLoading={props.isTemplatesLoading} />
        {props.templatesData
            ? <>
                {renderTemplates()}
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                />
            </>
            : <></>
        }
    </>;
};

const makeOrGetMapStateToProps = () => {
    const getTemplatesFiltersByPageKey = makeOrGetTableFiltersByKey();
    const mapStateToProps = (state) => ({
        tableFilters: getTemplatesFiltersByPageKey(state, 'templates'),
        templatesData: getTemplatesState(state),
        totalRecords: state.templatesReducer.totalRecords,
        isTemplatesLoading: state.templatesReducer.isTemplatesLoading,
    });
    return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
    setTableFiltersAction: (tableFilters) => {
        UseTableActions.setTableFilters(dispatch, tableFilters);
    },
    resetFiltersAction: () => {
        UseTableActions.resetFilters(dispatch);
    },
    dispatch
});

export default connect(makeOrGetMapStateToProps, mapDispatchToProps)(TemplatesTable);