import * as types from '../../actionTypes/common/UseTableActionTypes';

const useTableInitialState = {
  tableFilters:{}
};

const useTableReducer = (state = useTableInitialState, action) => {
  switch (action.type) {
    case types.RESET_FILTERS: {
      return {
        ...state,
        tableFilters: useTableInitialState.tableFilters,
      };
    }
    case types.SET_TABLE_FILTERS_DATA: {
      const { pageKey, newFilters } = action.data;
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [pageKey] : {
            ...state.tableFilters[pageKey],
            ...newFilters,
          }
        }
      }
    }
    default:
      return state;
  }
};

export { useTableInitialState, useTableReducer };
