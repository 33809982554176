import React from 'react';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, InputAdornment } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as UseTableActions from '../../redux/actionCreators/common/UseTableActionCreators';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';

/** Search Input Component */
const SearchInput = (props) => {
  const { search, orderBy, order } = props.tableFilters;

  const { addSerachInputToUrl = true } = props;
  const handleSearchChange = (event) => {
    handleSetTableFilters(event.target.value);
  };

  const handleSetTableFilters = (searchValue) => {
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        search: searchValue,
        page: 0,
      },
    };
    props.setTableFiltersAction(filterValue);
  };

  const handleEnterSearch = () => {
    handleSetTableFilters(search);
    if (addSerachInputToUrl) {
      props.dispatch(
        push({
          search: `?${new URLSearchParams({
            order,
            orderBy,
            page: 0,
            searchInput: search || '',
          }).toString()}`,
        })
      );
    }
    props.onSearchAction();
  };

  return (
    <TextField
      label="Search"
      value={search}
      InputProps={{
        startAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleSearchChange}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
          handleEnterSearch();
        }
      }}
    />
  );
};

const makeOrGetMapStateToProps = () => {
  const getTableFiltersByPageKey = makeOrGetTableFiltersByKey();
  const mapStateToProps = (state, ownProps) => ({
    tableFilters: getTableFiltersByPageKey(state, ownProps.pageKey),
  });
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  setTableFiltersAction: (tableFilters) => {
    UseTableActions.setTableFilters(dispatch, tableFilters);
  },
  dispatch,
});

export default connect(
  makeOrGetMapStateToProps,
  mapDispatchToProps
)(SearchInput);
