import { createSelector } from 'reselect';

// selector
const getTableFilters = (state) => state.useTableReducer.tableFilters;

const getKey = (state, pageKey) => pageKey;

const getPageKeyFiltersData = (filtersData, pageKey) => {
    const filtersObject = (filtersData && filtersData[pageKey]) || {};
    return Object.keys(filtersObject).length > 0 ? filtersObject : {
        order: 'desc',
        orderBy: 'id',
        search: '',
        page: 0,
    }
}

// reselect function to get table filters data by page key
export const makeOrGetTableFiltersByKey = () => createSelector(getTableFilters, getKey, getPageKeyFiltersData);
