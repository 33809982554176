import * as types from '../../actionTypes/common/ToastsActionTypes';

/** Action to open confirmation modal */
export const showToastMessage = (dispatch, data) => {
  const toast = {
    type: types.SHOW_TOAST_MESSAGE,
    data,
  };
  dispatch(toast);
};
