import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import Layout from './shared/layout/Layout';
import Routes from './Routes';
import { history } from './Store';
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/javascript/javascript.js";

function App() {
  return (
    <>
        <ConnectedRouter history={history}>
          <Layout>
            <Routes />
          </Layout>
        </ConnectedRouter>
    </> 
  );
}
export default App;
