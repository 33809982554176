import React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
  TableContainer,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import * as Actions from '../../redux/actionCreators/common/UseTableActionCreators';
import theme from '../../theme';
import  { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';

const useStyles = makeStyles(() => ({
  table: {
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.medBlue,

      '&:hover':{
        color: theme.palette.secondary.lightBlue,
      }
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f5f5f5',
      cursor: 'pointer',
    },
},}));
const themeMUI = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        lineHeight: '2.5rem',
      },
      root: {
        padding: '8px',
      },
    },
  },
});

const UseTable = (props) => {
  const classes = useStyles();
 
  const TblHead = () => {
    const  { orderBy, order } = props.tableFilters;
    
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId
        && order === 'asc';
      const cellOrder = isAsc ? 'desc' : 'asc';
      const filterValue = {
        pageKey: props.pageKey,
        newFilters: {
          order: cellOrder,
          orderBy: cellId,
          page: 0,
        }
      };
      props.setTableFiltersAction(filterValue);
    };

    return (
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={
                orderBy === headCell.id
                  ? order
                  : false
              }
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id
                      ? order
                      : 'asc'
                  }
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        page: newPage,
      }
    };
    props.setTableFiltersAction(filterValue);

  };

  const TblPagination = () => {
    const { page } = props.tableFilters;
    return (
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[]}
        rowsPerPage={10}
        count={props.totalRecords}
        onChangePage={handleChangePage}
      />
    );
  };
  return (
    <ThemeProvider theme={themeMUI}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          {TblHead(props)}
          {props.children}
        </Table>
        {TblPagination(props)}
      </TableContainer>
    </ThemeProvider>

  );
};

const makeOrGetMapStateToProps = () => {
  const getTableFiltersByPageKey = makeOrGetTableFiltersByKey();
  const mapStateToProps = (state, ownProps) => ({
    tableFilters: getTableFiltersByPageKey(state, ownProps.pageKey),
  });
  return mapStateToProps;
}

const mapDispatchToProps = (dispatch) => ({
  setTableFiltersAction: (tableFilters) => {
    Actions.setTableFilters(dispatch, tableFilters);
  },
});

export default connect(makeOrGetMapStateToProps, mapDispatchToProps)(UseTable);
