import React from 'react';
import { Button as IapButton, makeStyles } from '@material-ui/core';
import buttonStyles from './buttonStyles';

export default function Button(props) {
  const { text, size, color, variant, onClick, customButtonType, customRoot, ...other } = props;
  let useStyles;

  if(!customRoot) {
    useStyles = makeStyles((theme) => ({
      root: {
        margin: theme.spacing(0.5),
        boxShadow: 'none',
      },
      label: {
        textTransform: 'none',
      }
    }));
  }
  if (customButtonType){
    if(customButtonType === "buttonGroupSingle")
      useStyles = makeStyles(() => ({root: buttonStyles.buttonGroupSingle.root, label: buttonStyles.buttonGroupSingle.label}));

  }
  else {
    useStyles = makeStyles(() => ({
      root: customRoot,
      label: {
        textTransform: 'none',
      }
    }));
  }

  const classes = useStyles();

  return (
    <IapButton
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      onClick={onClick}
      {...other}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {text}
    </IapButton>
  );
}
