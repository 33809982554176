import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import LoginBG from '../../assets/Images/Login-Hero-1.jpg';
import { cognitoApiEndPoints } from '../../constants/apiConstants';
import CustomButton from '../../shared/button/Button';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    minHeight: 'calc(100vh - 10rem)',
    backgroundImage: `url(${LoginBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  loginCard: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
    padding: '2rem',
    marginTop: '2rem',
    marginRight: '4rem',
    marginBottom: '2rem',
    borderRadius: '4px',
    width: '40vw',
    maxWidth: '375px',
    opacity: 0.9,
  },
  loginHeader: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  loginHeaderText: {
    color: theme.white,
    textAlign: 'center',
    fontSize: '2rem',
  },
  line: {
    borderTop: `1px solid ${theme.palette.blue}`,
    textAlign: 'center',
    marginTop: '1rem',
  }
}));

const Login = () => {
  const classes = useStyles();
  const login = async () => {
    window.location.href = cognitoApiEndPoints.loginURL;
  };

  return (
    <div className={classes.loginContainer}>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
        <div className={classes.loginCard}>
          <div className={classes.loginHeader}>
            <div className={classes.loginHeaderText}>Log in to Notification Service</div>
            <div className={classes.line} />
          </div>
          <div>
            <CustomButton
              onClick={login}
              type="primary"
              label="Login"
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default withRouter(Login);
