import * as types from '../../actionTypes/common/UseTableActionTypes';

/** Action to reset table filters */
export const resetFilters = (dispatch) => {
  const reset = {
    type: types.RESET_FILTERS,
  };
  dispatch(reset);
};

/** Action to set table filters */
export const setTableFilters = (dispatch, data) => {
  const setFilters = {
    type: types.SET_TABLE_FILTERS_DATA,
    data,
  };
  dispatch(setFilters);
};
