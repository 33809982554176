import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ButtonLoader } from './ButtonLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '1rem',
    fontFamily: 'Reach-Text-Regular',
    fontSize: '1rem',
    border: '1px solid',
    color: theme.palette.common.white,
    outline: 'none',
    cursor: 'pointer',
  },
  medium: {
    padding: '0.8rem 1rem',
  },
  small: {
    padding: '0.6rem 1rem',
  },
  primary: {
    background: theme.palette.blue.light,
    border: `1px solid ${theme.palette.blue.light}`,
    '&:hover': {
      background: theme.palette.blue.lighter,
    },
  },
  error: {
    background: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.dark}`,
    '&:hover': {
      background: theme.palette.error.lighter,
    },
  },
  contained: {
    color: theme.palette.grey.dark,
    border: `1px solid ${theme.palette.grey.main}`,
    '&:hover': {
      background: theme.palette.grey.light,
    },
  },
  outlined: {
    color: theme.palette.blue.main,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.blue.main}`,
    '&:hover': {
      background: theme.palette.blue.light,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.blue.light}`,
    },
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
    color: '#ffff',
    verticalAlign: 'bottom',
    margin: '0 1rem',
  },
  disable: {
    opacity: 0.50,
    pointerEvents: 'none',
  },
}));
const CustomButton = ({
  type, label, onClick, loader, size = 'default', isDisable = false, id = '',
}) => {
  const classes = useStyles();
  return (
    <button
      disabled={isDisable || loader}
      data-testid={id}
      id={id}
      onClick={onClick}
      className={`${classes.root}  ${classes[type]} ${classes[size]} ${isDisable ? classes.disable : ''}`}
    >
      {loader && <ButtonLoader />}
      <span>{label}</span>
    </button>
  );
};
export default CustomButton;
