import theme from "../../theme";

const buttonStyles =   {   
    buttonGroupSingle: {
        root: {
            boxShadow: 'none',
            height: '2.65rem',
            color: '#00629b',
            backgroundColor: '#e6e6e6',
            "&:hover": {
                background: '#00629b',
                color: theme.palette.common.white,
            },
        },
        label: {
            textTransform: 'none',
        }
    },
};


export default buttonStyles;

