import axios from 'axios';
import * as types from '../actionTypes/TemplatesActionTypes';
import { notificationTemplateApiEndPoints } from '../../constants/apiConstants';
import qs from 'qs';
import { makeOrGetTableFiltersByKey } from '../selectors/common/UseTableSelectors';
import { showToastMessage } from './common/ToastsActionCreators';
import { push } from 'connected-react-router';
import { UseAuthorization } from '../../utils/UseAuthorization';
import { removeTokens } from '../../utils/AuthUtils';

const displayErrorToast = (dispatch, message) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: `Error while ${message}`,
    type: 'error',
  });
};

const displaySuccessToast = (dispatch, message) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: `${message} Successfully.`,
    type: 'success',
  });
};

/** notification-template endpoint success  */
export const fetchTemplatesSuccess = (data) => ({
  type: types.FETCH_NOTIFICATION_TEMPLATES_SUCCESS,
  data,
});

/** notification-template endpoint success  */
export const fetchTemplateByNameSuccess = (data) => ({
  type: types.FETCH_TEMPLATES_BY_NAME_SUCCESS,
  data,
});

/** notification-template endpoint failure  */
export const fetchTemplatesFailure = () => ({
  type: types.FETCH_NOTIFICATION_TEMPLATES_FAILURE,
});

/** notification-template endpoint request  */
export const fetchTemplatesRequest = (dispatch) => {
  const request = {
    type: types.FETCH_NOTIFICATION_TEMPLATES_REQUEST,
  };
  dispatch(request);
};

/** API Action to get templates data */
export const getNotificationTemplates = () => (dispatch, getState) => {
  const getTemplatesFiltersByPageKey = makeOrGetTableFiltersByKey();
  const pageFilters = getTemplatesFiltersByPageKey(getState(), 'templates');
  const config = UseAuthorization();
  const data = {
    start: pageFilters.page * 10,
    length: '10',
    sortColumn: pageFilters.orderBy,
    sortDirection: pageFilters.order,
    search: {
      value: pageFilters.search === undefined ? '' : pageFilters.search,
      regex: '',
    },
  };
  fetchTemplatesRequest(dispatch);
  axios.post(`${notificationTemplateApiEndPoints.fetchNotificationTemplates}`, data, config)
    .then((response) => {
      if (response.status === 200) {
        dispatch(fetchTemplatesSuccess(response.data));
      } else {
        dispatch(fetchTemplatesFailure());
      }
    })
    .catch((error) => {
      dispatch(fetchTemplatesFailure());
      dispatch(push({ pathname: '/logout' }));
      removeTokens();
      throw error;
    });
};

/** API Action to create templates data */
export const createTemplates = () => (dispatch, getState) => {
  const state = getState();
  const userEnteredInputs = state.templatesReducer.updatedTemplates;
  const data = {
    ...userEnteredInputs
  };
  const config = UseAuthorization();
  updateTemplatesRequest(dispatch);
  axios.post(`${notificationTemplateApiEndPoints.createTemplate}`, data, config)
    .then((response) => {
      if (response.status === 200) {
        displaySuccessToast(dispatch, 'Template Created');
        window.location.href = '/';
      } else {
        dispatch(updateTemplatesFailure());
        displayErrorToast(dispatch, 'creating template');
      }
    })
    .catch((error) => {
      dispatch(updateTemplatesFailure());
      displayErrorToast(dispatch, 'creating template');
      throw error;
    });
};

/** update-template endpoint request  */
export const updateTemplatesRequest = () => ({
  type: types.UPDATE_TEMPLATES_REQUEST,
});

/** update-template endpoint failure  */
export const updateTemplatesFailure = () => ({
  type: types.UPDATE_TEMPLATES_FAILURE,
});


/** API Action to update templates data */
export const updateTemplates = () => (dispatch, getState) => {
  const state = getState();
  const config = UseAuthorization();
  const userEnteredInputs = state.templatesReducer.updatedTemplates;
  const data = {
    ...userEnteredInputs
  };
  updateTemplatesRequest(dispatch);
  axios.put(`${notificationTemplateApiEndPoints.updateTemplate}`, data, config)
    .then((response) => {
      if (response.status === 200) {
        displaySuccessToast(dispatch, 'Template Updated');
        window.location.href = '/';
      } else {
        dispatch(updateTemplatesFailure());
        displayErrorToast(dispatch, 'Updating template');
      }
    })
    .catch((error) => {
      dispatch(updateTemplatesFailure());
      displayErrorToast(dispatch, 'Updating template');
      throw error;
    });
};

/** delete-template endpoint failure  */
export const deleteTemplatesRequest = () => ({
  type: types.DELETE_TEMPLATES_REQUEST,
});

/** delete-template endpoint failure  */
export const deleteTemplatesFailure = () => ({
  type: types.DELETE_TEMPLATES_FAILURE,
});

/** delete-template endpoint failure  */
export const deleteTemplatesSuccess = () => ({
  type: types.DELETE_TEMPLATES_SUCCESS,
});

/**  Delete template action */
export const deleteTemplate = (name) => async (dispatch) => {
  const config = UseAuthorization();
  dispatch(deleteTemplatesRequest());
  axios
    .delete(`${notificationTemplateApiEndPoints.deleteNotificationTemplate}?notificationTemplate=${name}`, config)
    .then((response) => {
      if (response.status === 200) {
        dispatch(deleteTemplatesSuccess());
        displaySuccessToast(dispatch, 'Template deleted');
        dispatch(getNotificationTemplates());
      } else {
        dispatch(deleteTemplatesFailure());
        displayErrorToast(dispatch, 'deleting template');
      }
    })
    .catch((error) => {
      dispatch(deleteTemplatesFailure());
      displayErrorToast(dispatch, 'deleting template');
      throw error;
    });
};

/** get templates info by ID */
export const getTemplateByNameAction = async (dispatch) => {
  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const templateName = parsed.name;
  const config = UseAuthorization();
  fetchTemplatesRequest(dispatch);
  axios
    .get(`${notificationTemplateApiEndPoints.getTemplateByName}?name=${templateName}`, config)
    .then(async (response) => {
      if (response.status === 200) {
        await dispatch(fetchTemplateByNameSuccess(response.data));
      } else {
        dispatch(fetchTemplatesFailure());
      }
    })
    .catch((error) => {
      dispatch(fetchTemplatesFailure());
      dispatch(push({ pathname: '/logout' }));
      removeTokens();
      throw error;
    });
};

/** get notification types endpoint request  */
export const getNotificationTypesRequest = () => ({
  type: types.GET_NOTIFICATION_TYPES_REQUEST,
});

/** get notification types endpoint failure  */
export const getNotificationTypesFailure = () => ({
  type: types.GET_NOTIFICATION_TYPES_FAILURE,
});

/** get notification types endpoint success */
export const getNotificationTypesSuccess = (data) => ({
  type: types.GET_NOTIFICATION_TYPES_SUCCESS,
  data,
});

const setUpdatedtemplatesData = (data) => ({
  type: types.SET_UPDATED_TEMPLATES_DATA,
  data,
});

/** setting data for edit mode */
export const setUpdatedtemplatesDataAction = (dispatch, data) => {
  dispatch(setUpdatedtemplatesData(data));
};

const resetUpdatedtemplatesData = () => ({
  type: types.RESET_UPDATED_TEMPLATES_DATA,
});

/** resetting the data set in edit mode */
export const resetUpdatedtemplatesDataAction = (dispatch) => {
  dispatch(resetUpdatedtemplatesData());
};

const setCognitoTokens = (data) => ({
  type: types.SET_COGNITO_TOKENS,
  data,
});

/** setting data for edit mode */
export const setCognitoTokensAction = (dispatch, data) => {
  dispatch(setCognitoTokens(data));
};