import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

export default function ActionButton(props) {
  const {
    color, children, onClick, size, type, text, customRoot,
  } = props;

  let useStyles;

  if(!customRoot) {
    useStyles = makeStyles((theme) => ({
      root: {
        minWidth: 0,
        margin: theme.spacing(0.5),
      },
    }));
  }

  const classes = useStyles();

  return (
    <Button className={`${classes.root} ${classes[color]}`} onClick={onClick} size={size} type={type} text={text}>
      {children}
    </Button>
  );
}