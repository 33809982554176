import * as types from '../../actionTypes/common/ToastsActionTypes';

const toastsInitialState = {
  isOpen: false,
  message: '',
  type: '',
};

const toastsReducer = (state = toastsInitialState, action) => {
  switch (action.type) {
    case types.SHOW_TOAST_MESSAGE:
      return {
        ...action.data,
      };
    default:
      return state;
  }
};

export { toastsInitialState, toastsReducer };