import React from 'react';
import {makeStyles, Paper, Toolbar, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    headerPageHeader: {
        display: 'flex',
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between',

        padding: '0px 0px 12px 0px',

        [`@media only screen and (min-width: 1563px)`]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0px 0px 12px 0px',
        },
    },
}));

/** Page Header Component, Accepts Page Title and Options to be rendered
 * using the props 'pageHeaderOptions'
 */
export default function PageHeader(props) {
    const classes = useStyles();
    const {title} = props;
    return (
        <Paper elevation={0} square>
            <Toolbar className={`${classes.headerToolbar} ${props.customHeaderToolbarClass}`}>
                <div className={classes.headerPageHeader}>
                    <div>
                        <Typography variant="h4" component="div">
                            {title}
                        </Typography>
                    </div>
                </div>
                <div className={props.customHeaderClass}>{props.pageHeaderOptions}</div>
            </Toolbar>
        </Paper>
    );
}