import React from 'react';
import { TextField } from '@material-ui/core';

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
    multiline,
    size,
    customInput,
    ...other
  } = props;
  const errorProps = error ? { error: true, helperText: error } : {};

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      multiline={multiline}
      size={size}
      {...other}
      {...errorProps}
    />
  );
}
